@import "../../../../sass/_colors";

.carousel {
    overflow: hidden;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        right: 0;
        width: 5rem;
        z-index: 999;
        height: 100%;
        background: linear-gradient(to right, rgba(255,0,0,0), $dark2);
        pointer-events: none; }
    .items {
        position: relative;
        display: flex;
        width: 100%;
        cursor: move;
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;
        //See More
        #see-more {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $gray4;
            transition: all 0.4s ease;
            &:hover {
                color: $dark;
                cursor: pointer;
                background-color: $dark4;
                .name {
                    color: $dark; } }
            svg {
                font-size: 3rem; }
            .name {
                font-size: 1.5rem; } } } }
