@import "../../sass/_colors";

footer {
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray3;
    background-color: $dark;
    a {
        text-decoration: none;
        margin-left: 0.5rem;
        font-weight: 600;
        color: inherit;
        transition: all 0.4s ease;
        &:hover {
            color: $color1; } } }
