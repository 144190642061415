@import "../../../../../sass/_colors";

.links {
    display: flex;
    margin-left: 2rem;
    a {
        cursor: pointer!important;
        opacity: 0.75;
        &:hover {
            opacity: 1; }
        .link {
            margin-left: 0.5rem;
            width: 3rem;
            height: 3rem;
            background-color: $dark2;
            color: $gray4;
            border-radius: 0.25rem;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.4s ease;
            border: 1px solid $dark3;
            svg {
                font-size: 2rem; }
            &:hover {
                color: $color1;
                border-color: $color1; } }
        &:first-child {
            .link {
                margin-left: 0; } } }

    //Spotify
    #spotify {
        &:hover {
            .link {
                border-color: $spotify;
                color: $spotify; } } }

    //Youtube
    #youtube {
        &:hover {
            .link {
                border-color: $youtube;
                color: $youtube; } } }

    //Twitch
    #twitch {
        &:hover {
            .link {
                border-color: $twitch;
                color: $twitch; } } }

    //Soundcloud
    #soundcloud {
        &:hover {
            .link {
                border-color: $soundcloud;
                color: $soundcloud; } } }

    //Instagram
    #instagram {
        &:hover {
            .link {
                border-color: $instagram;
                color: $instagram; } } }

    //Cafecitto
    #cafecito {
        position: relative;
        &:hover {
            opacity: 1;
            .link {
                border-color: $cafecito; }
            &::before {
                opacity: 1; } }
        &::before {
            pointer-events: none;
            content: "Invitar un cafecito ☕";
            position: absolute;
            width: 15rem;
            right: 3.5rem;
            height: 3rem;
            background-color: $cafecito;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            border-radius: 0.5rem;
            opacity: 0;
            transition: all 0.4s ease; } }

    @media screen and ( max-width: 1024px ) {
        margin-left: 0;
        margin-bottom: 1rem; }

    @media screen and ( max-width: 420px ) {
        a {
            .link {
                width: 2.75rem;
                height: 2.75rem;
                margin-left: 0.25rem;
                svg {
                    font-size: 1.75rem; } } } } }
