@import "../../../sass/_colors";

.video-embed-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $dark;
    border-radius: 1rem;
    .react-player {
        width: 50%;
        height: 30vw!important;
        min-height: 50vh;
        background-color: $dark;
        border: 4px solid $dark;
        border-radius: 1rem;
        overflow: hidden; }
    @media screen and ( max-width: 768px ) {
        .react-player {
            min-height: 10rem;
            height: 40vh!important; } }
    @media screen and ( max-width: 550px ) {

        .react-player {
            height: 20rem!important; } } }
