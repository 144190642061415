@import "../../../sass/_colors";

#playlist-modal {
    .card {
        display: flex;
        align-items: flex-start;
        min-height: 20rem;
        width: 100%;
        .left-wrapper {
            .top-left-wrapper {
                margin-top: 1rem;
                .genres {
                    display: flex;
                    margin-bottom: 1rem;
                    .genre {
                        color: $dark4;
                        text-transform: uppercase;
                        margin-right: 0.5rem;
                        background-color: $dark2;
                        padding: 0.5rem 0.75rem;
                        border-radius: 0.25rem;
                        font-size: 0.8rem;
                        &:last-child {
                            margin-right: 0; } } }
                .date {
                    background-color: $dark2;
                    width: fit-content;
                    padding: 0.75rem 2rem;
                    margin-bottom: 1rem;
                    color: $gray4;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 0.5rem; }
                .name {
                    font-size: 1.5rem;
                    font-weight: 600;
                    color: $gray; }

                .artists {
                    display: flex;
                    align-items: center;
                    margin-top: 0.5rem;
                    .artist {
                        font-size: 1.15rem;
                        color: $dark4;
                        display: flex;
                        &::before {
                            content: ",";
                            margin-right: 0.5rem; }
                        &:first-child {
                            &::before {
                                content: "";
                                margin-right: 0; } } }
                    .mix {
                        margin-left: 0.5rem;
                        color: $dark4;
                        font-size: 1.35rem; } } }
            .image {
                height: 20em;
                border-radius: 0.5rem 0.5rem 0 0;
                overflow: hidden;
                min-width: fit-content;
                border: 2px solid $dark2;
                transition: all 0.6s ease;
                &:hover {
                    transform: scale(1.05); }
                img {
                    max-height: 100%; } }
            .open-spotify {
                display: flex;
                align-items: center;
                justify-content: center;
                color: $gray;
                border: 2px solid $dark2;
                border-top: 0;
                padding: 1rem;
                font-size: 1.15rem;
                border-radius: 0 0 0.5rem 0.5rem;
                cursor: pointer;
                transition: all 0.4s ease;
                svg {
                    font-size: 1.5rem;
                    margin-right: 0.5rem; }
                &:hover {
                    background-color: $spotify;
                    border-color: $spotify2;
                    color: $dark; } } }

        .right-wrapper {
            margin-left: 1rem;
            min-width: 35rem;
            width: 30vw;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            color: $gray;
            iframe {
                border-radius: 0.5rem;
                overflow: hidden; } } }

    @media screen and ( max-width: 1024px ) {
        .card {
            flex-direction: column;
            display: block;
            max-height: 90vh;
            .image {
                display: flex;

                border: none;
                margin-bottom: 2rem;
                img {
                    border-radius: 0.5rem; } }

            .right-wrapper {

                margin-left: 0;
                .bottom-right-wrapper {
                    margin-top: 2rem; }

                .date {
                    margin-top: 1rem; } } } }

    @media screen and ( max-width: 550px ) {
        .card {
            flex-direction: column;
            display: block;
            max-height: 90vh;
            width: 90vw;

            .image {
                display: flex;
                justify-content: center;
                border: none;
                margin-bottom: 2rem;
                height: 15em;
                img {
                    border: 1px solid $dark2;
                    border-radius: 0.5rem; } }

            .left-wrapper {

                .top-left-wrapper {
                    .genres {
                        justify-content: center; }
                    .name {
                        font-size: 1.5rem; }
                    .artists {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 1rem;
                        .artist {
                            font-size: 1rem;
                            &::before {
                                margin-right: 0.25rem; } }
                        .mix {
                            font-size: 1rem; } } }
                .date {
                    margin-top: 1rem; } } } } }
