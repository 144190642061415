@import "../../../sass/_colors";

#song-modal {
    .card {
        display: flex;
        align-items: center;
        min-height: 20rem;
        max-height: 50vh;
        width: 100%;
        .left-wrapper {
            .image {
                height: 30rem;
                border-radius: 0.5rem 0.5rem 0 0;
                overflow: hidden;
                min-width: fit-content;
                border: 2px solid $dark2;
                transition: all 0.6s ease;
                border-bottom: 0;
                img {
                    max-height: 100%; } }
            iframe {
                border-radius: 0 0 0.25rem 0.25rem;
                margin-bottom: 2rem;
                overflow: hidden; } }
        .right-wrapper {
            margin-left: 2rem;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            color: $gray;

            .top-right-wrapper {
                .genres {
                    display: flex;
                    margin-bottom: 1rem;
                    .genre {
                        color: $dark4;
                        text-transform: uppercase;
                        margin-right: 0.5rem;
                        background-color: $dark2;
                        padding: 0.5rem 0.75rem;
                        border-radius: 0.25rem;
                        font-size: 0.8rem;
                        &:last-child {
                            margin-right: 0; } } }
                .date {
                    background-color: $dark2;
                    width: fit-content;
                    padding: 0.75rem 2rem;
                    margin-bottom: 1rem;
                    color: $gray4;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 0.5rem; }
                .name {
                    font-size: 2rem;
                    font-weight: 600; }

                .artists {
                    display: flex;
                    align-items: center;
                    margin-top: 0.5rem;
                    .artist {
                        font-size: 1.35rem;
                        color: $dark4;
                        display: flex;
                        &::before {
                            content: ",";
                            margin-right: 0.5rem; }
                        &:first-child {
                            &::before {
                                content: "";
                                margin-right: 0; } } }
                    .mix {
                        margin-left: 0.5rem;
                        color: $dark4;
                        font-size: 1.35rem; } } }
            .bottom-right-wrapper {
                margin-top: 4rem;
                .text {
                    font-size: 1.25rem;
                    color: $gray;
                    margin-bottom: 1rem; }
                .buttons {
                    display: flex;
                    margin-top: 0.5rem;
                    .button {
                        display: flex;
                        width: fit-content;
                        background-color: transparent;
                        color: $dark4;
                        height: 4rem;
                        align-items: center;
                        justify-content: center;
                        width: 4rem;
                        border-radius: 0.5rem;
                        margin-right: 0.5rem;
                        transition: all 0.4s ease;
                        border: 1px solid $dark4;
                        filter: grayscale(1);
                        opacity: 1;
                        &:hover {
                            transform: scale(1.05);
                            cursor: pointer;
                            filter: grayscale(0) brightness(1);
                            opacity: 1;
                            border-color: $gray4; }
                        svg {
                            font-size: 2rem; } }
                    #spotify {
                        &:hover {
                            border-color: $spotify;
                            color: $spotify; } }
                    #soundcloud {
                        &:hover {
                            border-color: $soundcloud;
                            color: $soundcloud; } }
                    #youtube {
                        &:hover {
                            border-color: $youtube;
                            color: $youtube; } }
                    #tidal {
                        &:hover {
                            border-color: $gray;
                            color: $gray; } }
                    #beatport {
                        &:hover {
                            border-color: $beatport;
                            img {
                                filter: brightness(1); } }
                        img {
                            filter: brightness(0.6);
                            width: 70%; } }
                    #external {

                        margin-left: 1.5rem; } } } } }

    @media screen and ( max-width: 1024px ) {
        .card {
            flex-direction: column;
            display: block;
            max-height: 90vh;
            .left-wrapper {
                .image {
                    display: flex;
                    justify-content: center;
                    background-color: $dark2;
                    height: 15rem;
                    border: none;

                    img {
                        border-radius: 0; } } }

            .right-wrapper {

                margin-left: 0;
                .bottom-right-wrapper {
                    margin-top: 2rem; }

                .date {
                    margin-top: 1rem; } } } }

    @media screen and ( max-width: 550px ) {
        .card {
            flex-direction: column;
            display: block;
            max-height: 90vh;
            width: 90vw;

            .image {
                display: flex;
                justify-content: center;
                border: none;

                height: 5em;
                img {
                    border: 1px solid $dark2;
                    border-radius: 0.5rem; } }

            .right-wrapper {
                align-items: center;
                justify-content: center;
                text-align: center;
                margin-left: 0;
                width: 100%;
                .bottom-right-wrapper {
                    margin-top: 2rem;
                    width: 100%;
                    .text {
                        font-size: 1rem; }
                    .buttons {
                        align-items: center;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        width: 100%;
                        .button {
                            height: 3rem;
                            width: 3rem;
                            margin-right: 0;
                            margin-bottom: 0.5rem; }
                        #external {
                            margin-left: 0; } } }
                .top-right-wrapper {
                    .genres {
                        justify-content: center; }
                    .name {
                        font-size: 1.5rem; }
                    .artists {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 1rem;
                        .artist {
                            font-size: 1rem;
                            &::before {
                                margin-right: 0.25rem; } }
                        .mix {
                            font-size: 1rem; } } }
                .date {
                    margin-top: 1rem; } } } } }
