@import "../../../sass/_colors";

.genres-carousel {
    width: 100%;
    background-color: $dark;
    height: 3.5rem;
    z-index: 99;
    .container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        .genre {
            background-color: $dark2;
            color: $gray4;
            padding: 0.5rem 1rem;
            min-width: fit-content;
            margin-right: 0.5rem;
            border-radius: 5rem;
            text-decoration: none;
            &:last-child {
                margin-right: 0; } } } }
