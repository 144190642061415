//Fonts

//Colors
@import "./_colors";

*,
*::before,
*::after {
    box-sizing: border-box; }

a {
    color: inherit;
    text-decoration: none; }

body {
    font-family: "Poppins"; }

.App {
    max-width: 100vw;
    overflow-x: hidden; }

//Container
.container {
    width: 100%;
    padding: 0 7.5vw;
    @media screen and ( max-width: 1250px ) {
        padding: 0 5vw; }
    @media screen and ( max-width: 1024px ) {
        padding: 0 2.5vw; }
    @media screen and ( max-width: 550px ) {
        padding: 0 0.75rem; } }

//View
.view {
    min-height: 100vh;
    background-color: $dark; }

//Scrollbar
::-webkit-scrollbar {
    width: 25px;
    margin-top: 0; }

::-webkit-scrollbar-thumb {
    width: 25px;
    border-left: 6px solid rgba(0, 0, 0, 0);
    border-right: 6px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05); }

::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none; }

::-webkit-scrollbar-corner {
    background-color: transparent; }
