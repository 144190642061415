@import "../../../sass/_colors";

button {
    background-color: $dark;
    border: none;
    color: $gray;
    padding: 1rem 2rem;
    width: 100%;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 999; }
