$dark: #141414;
$dark2: #1f1f1f;
$dark3: #3d3d3d;
$dark4: #6d6d6d;
$white: #f2f2f2;
$gray: #e7e7e7;
$gray2: #d3d3d3;
$gray3: #bdbdbd;
$gray4: #acacac;
$gray5: #9e9e9e;

$color1: #64e8ec;

//Brands
$spotify: #1ed760;
$spotify2: #1db954;
$beatport: #a4d11d;
$cafecito: #8fa2cc;
$youtube: #ff0000;
$soundcloud: #ff7f34;
$twitch: #6441a5;
$instagram: #c32aa3;
