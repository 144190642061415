@import "../../../../sass/_colors";

.user-info {
    width: 100%;

    .wrapper-top {
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .wrapper-top-left {
            display: flex;
            align-items: center;
            justify-content: center;
            .location {
                margin-right: 1rem;
                background-color: $white;
                padding: 0 1.5rem;
                height: 3rem;
                color: $dark;
                border-radius: 0.25rem;
                display: flex;
                align-items: center;
                justify-content: center;
                display: none;
                svg {
                    font-size: 1.5rem;
                    margin-right: 0.25rem; } } } }

    .occupations {
        display: flex; }
    .occupations-resp {
        display: none; }
    .description-resp {
        display: none; }

    .occupation {
        padding: 0 1.5rem;
        height: 3rem;
        border-radius: 0.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $white;
        margin-right: 0.5rem; }
    .resp {
        display: none; }

    .wrapper-bottom {
        .wrapper-bottom-left {
            display: none; }
        .wrapper-bottom-right {
            .name {
                font-size: 6vw;
                line-height: 6.5vw;
                font-weight: 800;
                margin-top: 2rem; }

            .description {
                margin-top: 1rem;
                font-weight: 300;
                color: $gray4;
                font-size: 1.1rem;
                max-width: 60em; } } }

    @media screen and ( max-width: 1250px ) {
        .name {
            margin-top: 2rem;
            font-size: 7vw;
            line-height: 7vw; } }

    @media screen and ( max-width: 1024px ) {
        .wrapper-top {
            flex-direction: column-reverse;
            align-items: flex-end; }
        .description {
            max-width: none; }
        .wrapper-bottom {
            .wrapper-bottom-right {
                .name {
                    margin-top: 2rem;
                    font-size: 3rem;
                    line-height: 3.5rem; } } } }

    @media screen and ( max-width: 550px ) {

        .wrapper-top {
            .wrapper-top-left {
                flex-direction: column;
                align-items: flex-end;
                .location {
                    margin-right: 0; } } }
        .wrapper-bottom {
            display: flex;
            align-items: center;
            margin-top: 2rem;
            .wrapper-bottom-right {
                width: 100%;
                .name {
                    margin-top: 0;
                    font-size: 7vw;
                    line-height: 8vw; }
                .description {
                    display: none; } }
            .wrapper-bottom-left {
                display: flex;
                .profile-image-resp {
                    margin-right: 1rem;
                    width: 5rem;
                    height: 5rem;
                    background-size: cover;
                    border-radius: 50%; } } }
        .occupations {
            display: none; }

        .occupations-resp {
            display: flex;
            margin-top: 0.5rem;
            .occupation {
                height: 2rem;
                padding: 1rem;
                font-size: 0.9rem;
                &:last-child {
                    margin-right: 0; } } }

        .description-resp {
            display: flex;
            margin-top: 1rem;
            color: $gray4; } } }
