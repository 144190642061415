// @import "../../../sass/globalStyles"
@import "../../../sass/_colors";

.user-header {
    color: #fff;
    // min-height: 20rem
    display: flex;
    align-items: flex-end;
    box-shadow: rgba(20, 20, 20, 0.2) 0px 8px 24px;
    position: relative;
    overflow: hidden;
    //Cover Image
    .cover-image {
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: top;
        z-index: 0;
        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.75); } }

    .container {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        padding-bottom: 2rem;
        padding-top: 10rem;
        z-index: 99;
        //Profile Image
        .profile-image {
            background-color: $dark4;
            border: 1px solid $dark3;
            min-width: 15rem;
            min-height: 15rem;
            margin-right: 2em;
            border-radius: 50%;
            background-size: cover;
            background-position: center; } }

    //Media Queries
    @media screen and ( max-width: 1024px ) {
        align-items: flex-start;
        .container {
            padding-top: 2rem;
            .profile-image {
                position: absolute;
                top: 2rem;
                min-width: 7rem;
                min-height: 7rem; } } }

    @media screen and ( max-width: 550px ) {
        .container {
            .profile-image {
                display: none; } } } }
