
//Components
@import "./UserHeader";
@import "../../sass/_colors";

#user {
    background-color: $dark;
    #user-content {
        min-height: 100vh;
        background-color: $dark2;
        padding-top: 2rem;
        padding-bottom: 4rem;

        .video-links-wrapper {
            display: flex;
            max-height: 60vh;
            margin: 2rem 0;
            .other-links-wrapper {
                margin-left: 1rem;
                width: 40vw;
                max-height: 60vh;
                overflow: hidden;
                position: relative;
                &::before {
                    content: "";
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 3rem;
                    z-index: 999;
                    background: linear-gradient(to bottom, rgba(255,0,0,0), $dark2);
                    pointer-events: none; }
                .other-links {
                    height: 100%;
                    overflow-y: auto;
                    .link {
                        background-color: $dark;
                        border: 2px solid $dark2;
                        color: $gray4;
                        display: block;
                        padding: 1.25rem;
                        margin-bottom: 0.5rem;
                        font-size: 1.25rem;
                        border-radius: 0.5rem;
                        cursor: pointer;
                        text-decoration: none;
                        transition: all 0.4s ease;
                        .title {
                            font-size: 1.15rem;
                            color: $gray2; }
                        .description {
                            margin-top: 0.5rem;
                            font-size: 1rem;
                            color: $gray4; }
                        &:hover {
                            background-color: $dark2;
                            border: 2px solid $dark; }
                        &:last-child {
                            margin-bottom: 5rem; } } }
                .text {
                    color: $gray;
                    font-size: 1.5rem;
                    margin-bottom: 1rem; } } } }
    @media screen and ( max-width: 1200px ) {
        #user-content {
            .video-links-wrapper {
                flex-direction: column;
                max-height: none;
                .other-links-wrapper {
                    width: 100%;
                    margin-left: 0;
                    margin-top: 3rem;
                    max-height: none;
                    .other-links {
                        .link {
                            &:last-child {
                                margin-bottom: 2.5rem; } } } } } } }
    @media screen and ( max-width: 768px ) {
        #user-content {
            .video-links-wrapper {
                margin: 1rem 0; } }

        // .genres
        //     display: flex
        //     margin-bottom: 1rem
        //     .genre
        //         background-color: $dark4
        //         color: $white
        //         padding: 0.5rem 1rem
        //         margin-right: 0.5rem
        //         border-radius: 0.25rem
        //         cursor: pointer
        //         transition: all 0.2s ease
        //         &:hover
        //             opacity: 0.85
        //         &:last-child
 } }        //             margin-right: 0
