// @import "../../../sass/globalStyles"
@import "../../../sass/_colors";

.user-section {
    padding: 2em 0;
    .empty {
        opacity: 0.5;
        background-color: $dark3;
        color: $gray4;
        font-size: 1.25rem;
        padding: 2rem;
        border-radius: 0.5rem; }

    .section-top-wrapper {
        width: 100%;
        margin-bottom: 1rem;
        // border-bottom: 1px solid $dark4
        .section-title {
            font-size: 2rem;
            font-weight: 600;
            color: $gray; } }

    //Media Queries
    @media screen and ( max-width: 768px ) {
        padding: 1.5em 0;
        .empty {
            padding: 1rem;
            font-size: 1rem; }
        .section-top-wrapper {
            .section-title {
                font-size: 1.5rem; } } } }

//Tracks Section
#tracks {}
