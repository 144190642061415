@import "../../../sass/_colors";

.selections {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .selection-card {
        width: 50%;
        background-color: $dark;
        color: $gray;
        padding: 1rem;
        display: flex;
        align-items: center;
        border-radius: 0.5rem;
        transition: all 0.4s ease;
        .image {
            min-height: 5rem;
            min-width: 5rem;
            background-color: $dark3;
            border: 1px solid $dark4;
            border-radius: 0.5rem; }
        .text {
            margin-left: 1rem;
            .top-text {
                margin-bottom: 0.5rem;
                text-transform: uppercase;
                color: $gray4; }
            .name {
                font-size: 1.25rem; } }
        &:first-child {
            margin-right: 1rem; } }

    @media screen and ( max-width: 768px ) {
        flex-wrap: wrap;
        .selection-card {
            margin-bottom: 1rem;
            width: 100%;
            &:first-child {
                margin-right: 0; } } } }
