@import "../../../sass/_colors";

.modal {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .content {
        z-index: 999;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .card {
            background-color: $dark;
            border: 2px solid $dark2;
            width: 100%;

            padding: 2rem;
            padding-top: 3rem;
            // padding-top: 4rem
            border-radius: 0.5rem;
            position: relative;
            .close-button {
                position: absolute;
                right: 2rem;
                top: 0;
                width: 2.5rem;
                height: 2.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.4s ease;
                color: $gray4;
                background-color: $dark2;
                border-radius: 0 0 0.25rem 0.25rem;
                @media screen and ( max-width: 768px ) {
                    width: 2.5rem;
                    height: 2.5rem;
                    right: 1rem;
                    svg {
                        font-size: 1.25rem; } }
                svg {
                    font-size: 1.5rem; }
                &:hover {
                    color: $gray;
                    background-color: $dark3;
                    cursor: pointer; } } } }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #000000ad;
        backdrop-filter: blur(4px);
        width: 100%;
        height: 100%; }

    @media screen and ( max-width: 1024px ) {
        padding: 0 4rem;
        .card {
            min-width: 0; } }

    @media screen and ( max-width: 550px ) {
        .content {
            .card {
                padding: 1rem;
                padding-top: 3rem; } } } }
