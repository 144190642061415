@import "../../../../../sass/_colors";

.item {
    min-width: 15rem;
    max-width: 15rem;
    margin: 0;
    min-height: 15em;
    background-color: $dark3;
    margin-right: 1rem;
    border-radius: 0.25rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.75rem;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    position: relative;


    .item-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        height: fit-content;
        height: 100%;
        width: 100%;
        button {
            margin-top: 1rem;
            transition: all 0.4s ease;
            &:hover {
                transform: scale(1.025); } }
        .name {
            color: $gray4;
            margin-top: 1rem;
            font-size: 1rem;
            line-height: 1.25rem;
            text-align: left; }
        .artist {
            margin-top: 0.5rem;
            align-items: flex-start;
            text-align: left;
            width: 100%;
            display: flex;
            color: $dark4; } }

    .image {
        border-radius: 0.25rem;
        overflow: hidden;
        min-width: 13.5rem;
        min-height: 13.5rem;
        background-size: cover;
        background-position: center;
        transition: all 0.4s ease;
        box-shadow: rgba(0, 0, 0, 0.0) 0px 1px 2px 0px; }


    .date {
        left: 1.5rem;
        top: 1.5rem;
        padding: 0.5rem 0.75rem;
        background-color: $dark;
        color: $white;
        border-radius: 0.25rem;
        position: absolute;
        z-index: 99;
        border: 1px solid $dark3; }

    &:hover {
        .image {
            cursor: pointer; } }

    @media screen and ( max-width: 550px ) {
        padding: 0.75rem;
        min-width: 15rem;
        max-width: 12rem;
        .date {
            left: 1.25rem;
            top: 1.25rem; }
        .image {
            width: 10rem;
            height: 10rem; } } }

//Wide
.wide {
    min-width: 25rem;
    .image {
        width: 23.5rem; }

    @media screen and ( max-width: 550px ) {
        min-width: 18rem;
        min-height: 10rem;
        .image {
            width: 16.5rem;
            min-height: 10rem; } } }
